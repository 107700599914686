import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import HeroSection from '../../components/Blocks/HeroSection/heroSection';
import LinkText from '../../components/Blocks/LinkText/linkText';
import Paragraph from '../../components/Blocks/Paragraph/paragraph';
import Section from '../../components/Blocks/Section/section';
import Layout from '../../components/layout';
import SEO from '../../components/SEO/seo';

const KomilfoPage = () => {
  const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `,
  );

  return (
    <Layout backto="/selfhelp">
        <SEO lang="ru" title={'Designing your life'} keywords={['дизайн', 'UX', 'UI', 'Artyom Alekseev', 'Артём Алексеев', 'website', 'usability', 'юзабилити', 'selfhelp', 'селфхелп']} description="Стоит ли читать книгу Дизайн вашей жизни, краткое содержание. Как применить дизайн-мышление к жизни."/>

        <HeroSection animationType="noanima" heading="Селфхелп книга Designing your life" desc="Как использовать дизайн-мышление, чтобы сделать свою жизнь лучше. Обсуждаем книгу и выделяем основные концепты."/>

        <Section heading="Книга">
            <Paragraph>Про эту книгу я узнал на сайте в <LinkText href="https://dschool.stanford.edu/resources/dschool-reading-list">библиографии курса d.school о дизайн-мышлении</LinkText>. Дизайн мышление это методология которая помогает многим продуктовым командам мира создавать инновационные продукты которые при этом будут нужны покупателям.</Paragraph>
            <Paragraph>Процесс дизайн мышления начинается с изучения покупателей — людей которые в будущем будут использовать ваш продукт. Какие у них есть потребности. Потом мы накидываем кучу идей, как решить проблемы которые есть у пользователей, далее создаем прототипы, максимально простые, но с помощью которых можно проверить, решает ли эта идея проблему человека. Потом, мы тестируем прототипы и повторяем цикл. Какие-то прототипы улучшаем. какие-то выкидываем и узнаем новое про наших покупателей.</Paragraph>
            <Paragraph>И у Дейва Эвенса и Билла Бернета появилась идея применить эту методологию к жизни. И мне кажется у них хорошо получилось.</Paragraph>
        </Section>

        <Section heading="Прототипирование">
            <Paragraph>Первое это прототипирование. Прототипирование это те самые простейшие первые версии продукта которые мы создаем для того чтобы протестировать продукт. Прототипирование также можно применить и к жизни. Например, вы на распутье и не знаете чем вам заняться в жизни. Вы можете пойти учиться в университете 7 лет на доктора и потом понять что это вам вовсе не нравится.</Paragraph>
            <Paragraph>Чтобы не допустить такого, стоит как можно раньше попробовать будущую работу, заранее больше узнать про неё и видим ли мы себя в ней. То есть мы создаем прототип работы. </Paragraph>
            <Paragraph>Как это сделать? Например узнать больше про день из жизни человека который этим занимается, почитать форумы. А лучше напроситься и пойдти стажером или просто посмотреть на работу в действии.</Paragraph>
        </Section>

        <Section heading="Workview и Lifeview">
            <Paragraph>Еще важный совет который дает книга, это составить два документа — Lifeview и Workview. Что это такое? Это в самом деле два документа, в которых вы описываете ваше отношение к жизни и работе. В Workview вы описываете что для вас важно в работе, вашу работу мечты. В Lifeview вы описываете свои приоритеты в жизни. После того как вы составили эти два документы, вам нужно сравнить их и определить в гармонии ли они.</Paragraph>
            <Paragraph>Например, вы считаете, что все бизнесы мира должны быть этическими. А вы при этом работаете в компании которая не эко-френдли или в букмекерской конторе, то эти два документа не как не сойдутся, не смотря на то, что в Workview у вас написано, что вам важно чтобы работа была прибыльной.</Paragraph>
            <Paragraph>Как это сделать? Например узнать больше про день из жизни человека который этим занимается, почитать форумы. А лучше напроситься и пойдти стажером или просто посмотреть на работу в действии.</Paragraph>
        </Section>

        <Section heading="Поиск работы мечты">
            <Paragraph>Еще один момент о котором рассказывается в этой книге это поиск работы мечты. Все мы знаем как сложно найти работу, особенно в интернете. </Paragraph>
            <Paragraph>Примерно 70% компаний на вакансий которых вы откликнетесь — вас проигнорируют, 25% — откажут, и только 5% позовут на интервью (хотя это только начало общения).</Paragraph>
            <Paragraph>Почему это так? Те самые 70% игнора, появляются по разным причинам. Возможно вы не приглянулись, может бот вас не заметил, может вы написали технологию не так как обычно и рекрутер не понял, хотя для вас это очевидно.</Paragraph>
            <Paragraph>Нужно понимать что рекрутеры, даже те которые называют себя IT-рекрутерами далеко не факт, что достаточно разбираются во всех специальностях которые относятся к IT.</Paragraph>
            <Paragraph>Но даже если вы добьетесь интервью, вам еще придется пройти девять кругов ада, которые включают в себя определенное число собеседований с рекрутерами, менеджерами, командой, при этом сделать тестовое задание и даже после этого у вас будет тестовый период в который вам почти не будут платить.</Paragraph>
            <Paragraph>Из этого даже выросла моя концепция о поиске работы, что либо ты успешный человек, с кучей проектов за спиной и медийной известностью, либо у тебя есть связи. </Paragraph>
            <Paragraph>И про связи, в книге Designing your life, рассказывается очень много. Про то как найти новые связи и как показать что вы хороший кандидат для компании.</Paragraph>
        </Section>

        <Section heading="Нужна ли эта книга?">
            <Paragraph>И в каком случае стоит покупать эту книгу. Во-первых, если вы хотите порефлексировать, изучить себя по-лучше. Или работа не приносит вам удовольствия и вы не можете определиться чем вам заниматься.</Paragraph>
            <Paragraph>Также, эта книга поможет выпускникам школ, которые не могут выбрать чем заниматься дальше. А таких, как мне кажется, большинство.</Paragraph>
        </Section>

        <Section heading="В заключении">
            <Paragraph>Вообще мне настолько понравилась книга, что я купил печатное издание в оригинале и очень доволен этим. Кстати книга <LinkText href="https://www.chitai-gorod.ru/catalog/book/1015604/?utm_source=google&utm_medium=cpc&utm_campaign=Beta_PLA_rf&utm_term=&utm_content=g|position|u|||ad|270933252449||device|c|geo||9047065|cid|1410957570|gid|54935583029|pla-437148130704&gclid=Cj0KCQiAnKeCBhDPARIsAFDTLTLQlV4D1VDeY1iZiVn9-V4f7Ia1IczR0VZE3UveMr8NSaEDyW9yNvIaAt54EALw_wcB">есть</LinkText> и в русском печатном издании.</Paragraph>
            <Paragraph>Также, эта книга поможет выпускникам школ, которые не могут выбрать чем заниматься дальше. А таких, как мне кажется, большинство.</Paragraph>
            <Paragraph>На этом все, спасибо что читали, надеюсь вам понравилось. Пока. </Paragraph>
        </Section>
    </Layout>
  ); };

export default KomilfoPage;
